import { createAsyncThunk } from '@reduxjs/toolkit';
import { changePassword } from 'shared/api/password';

export interface IChangePassword {
    token: string;
    password: string;
    newPassword: string;
}

export const fetchChangePassword = createAsyncThunk(
    'changePassword/fetchAll',
    async (data: IChangePassword, thunkAPI) => {
        const { token, password, newPassword } = data;
        try {
            const response = await changePassword(
                token,
                password,
                newPassword
            );
            if (response.data?.status === 400) {
                return thunkAPI.rejectWithValue(response.data?.errorMessage);
            } else {
                return thunkAPI.fulfillWithValue('Пароль успешно изменён.');
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Не удалось изменить пароль.');
        }
    }
);
