import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios'
import { setError } from 'features/auth/login/model/loginSlice'
import { router } from 'app/app'
import { ERROR_TOKEN } from 'shared/consts/constants'
import { AxiosErrorData } from 'shared/types/apiTypes'
import { API_URL, BASIC_TOKEN } from 'shared/consts/config'

let store: any

export const injectStore = (_store: any) => {
  store = _store
}

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 120000,
})

const reqHandler = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  config.headers!.Authorization = BASIC_TOKEN

  const token = localStorage.token

  if (!token) {
    window.location.href = '/authorization'
    return config
  }

  config.headers.token = token
  return config
}

const resHandlerError = (error: AxiosError<AxiosErrorData>): Promise<AxiosError> => {
  if (error.response?.status === 401) {
    localStorage.removeItem('token')
    store.dispatch(setError(ERROR_TOKEN))
    router.navigate('/authorization')
  }

  return Promise.reject(error)
}

axiosInstance.interceptors.request.use(reqHandler)
axiosInstance.interceptors.response.use((response) => response, resHandlerError)

export default axiosInstance
