import { ITableHeader } from '@sf/sibgu.package.ui/dist/types/components/Table/types';
import { IIntermediateAttestationItem } from 'entities/intermediateAttestation/modal/intermediateAttestationSlice';

export const INTERIM_ATTESTATION_TABLE_HEADERS: ITableHeader<
    keyof IIntermediateAttestationItem
>[] = [
    { name: 'Дисциплина', value: 'subject' },
    {
        name: 'Форма контроля',
        value: 'controlType',
    },
    { name: 'Оценка', value: 'assessment' },
    { name: 'З.Е.', value: 'zet' },
    { name: 'Часы', value: 'hours' },
    { name: 'Дата', value: 'date' },
];

export const INTERIM_ATTESTATION_COLUMN_WIDTH: Record<
    keyof IIntermediateAttestationItem,
    number
> = {
    id: 0,
    subject: 30,
    controlType: 18,
    assessment: 18,
    zet: 10,
    hours: 15,
    date: 12,
};
