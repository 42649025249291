import React from 'react';
import './../../electronicEducational.scss';
export const OrganizationStandart: React.FC = () => {
    const subSections = [
        {
            title: 'Стандарт организации СТО 7.5.04-2019. Система менеджмента качества. Общие требования к построению, изложению и оформлению работ обучающихся.',
            link: 'https://disk.sibsau.ru/index.php/s/BUUWb8DmF8qkpwW',
        },
    ];
    const renderSectionList = () =>
        subSections.map((section, index) => (
            <a
                key={index}
                rel='noreferrer'
                href={section.link}
                target='_blank'
                className='science-library__item'>
                {section.title}
            </a>
        ));
    return (
        <div className='main-div'>
            <div className='d-flex jst-btw al-cent'>
                <div className='text title title-pos'>Стандарт организации</div>
            </div>
            <div className='spacer-3' />
            <div className='science-library__list'>{renderSectionList()}</div>
        </div>
    );
};
