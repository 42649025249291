import * as Yup from 'yup';
import {
    MIN_PASSWORD_LENGTH_ERROR,
    PASSWORD_STANDARD_ERROR,
    REQUIRED_ERROR,
} from 'shared/consts/constants';
import {
    CAPITAL_SYMBOLS,
    LOWERLINE_SYMBOLS,
    NUMBERS,
    SPECIAL_SYMBOLS,
} from 'shared/consts/regex';

export const formValidationPassword = Yup.object({
    password: Yup.string().required(REQUIRED_ERROR),
    newPassword: Yup.string()
        .min(8, MIN_PASSWORD_LENGTH_ERROR)
        .required(REQUIRED_ERROR)
        .matches(LOWERLINE_SYMBOLS, PASSWORD_STANDARD_ERROR)
        .matches(CAPITAL_SYMBOLS, PASSWORD_STANDARD_ERROR)
        .matches(NUMBERS, PASSWORD_STANDARD_ERROR)
        .matches(SPECIAL_SYMBOLS, PASSWORD_STANDARD_ERROR),
    confirmPassword: Yup.string()
        .min(8, MIN_PASSWORD_LENGTH_ERROR)
        .required(REQUIRED_ERROR)
        .matches(LOWERLINE_SYMBOLS, PASSWORD_STANDARD_ERROR)
        .matches(CAPITAL_SYMBOLS, PASSWORD_STANDARD_ERROR)
        .matches(NUMBERS, PASSWORD_STANDARD_ERROR)
        .matches(SPECIAL_SYMBOLS, PASSWORD_STANDARD_ERROR),
});
