import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrderList } from 'shared/api/personalData/types';
import { formatDate } from 'shared/utils/helpers';
import { fetchUserOrderList } from './thunk';

export interface IUserOrderListSlice {
    data: IOrderList[];
    loading: boolean;
    creditBookId: string;
}
const initialState: IUserOrderListSlice = {
    data: [],
    loading: false,
    creditBookId: '',
};

export const userOrderListSlice = createSlice({
    name: 'userOrderList',
    initialState,
    reducers: {
        setUserOrderListCreditBook(state, action: PayloadAction<string>) {
            state.creditBookId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserOrderList.fulfilled, (state, action) => {
            state.data = action.payload.orderList.map((order) => ({
                ...order,
                date: formatDate(order.date),
            }));
            state.loading = false;
        });
        builder.addCase(fetchUserOrderList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUserOrderList.rejected, (state) => {
            state.loading = false;
        });
    },
});

export const { setUserOrderListCreditBook } = userOrderListSlice.actions;

export default userOrderListSlice.reducer;
