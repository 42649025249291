import { ICurrentAttestationItem } from 'entities/currentAttestation/modal/currentAttestationSlice';
import { ITableHeader } from '@sf/sibgu.package.ui/dist/types/components/Table/types';

export const MAIN_HEADERS: ITableHeader<keyof ICurrentAttestationItem>[] = [
    { name: 'Дисциплина', value: 'subject' },
];

export const RATING_SCORE_HEADERS: ITableHeader<
    keyof ICurrentAttestationItem
>[] = [
    { name: 'Атт. 1', value: 'firstAttestation' },
    { name: 'Атт. 2', value: 'secondAttestation' },
    { name: 'Атт. 3', value: 'thirdAttestation' },
    { name: 'Прем. баллы', value: 'premiumPoints' },
    { name: 'Баллы за экз.', value: 'examPoints' },
    { name: 'Итого', value: 'totalPoint' },
];

export const INTERIM_ATTESTATION_HEADERS: ITableHeader<
    keyof ICurrentAttestationItem
>[] = [
    { name: 'Форма контроля', value: 'controlType' },
    { name: 'Оценка', value: 'assessment' },
];

export const CURRENT_ATTESTATION_TABLE_HEADERS: ITableHeader<
    keyof ICurrentAttestationItem
>[] = [
    ...MAIN_HEADERS,
    ...RATING_SCORE_HEADERS,
    ...INTERIM_ATTESTATION_HEADERS,
];

export const NUMBER_ALL_HEADER =
    MAIN_HEADERS.length +
    INTERIM_ATTESTATION_HEADERS.length +
    RATING_SCORE_HEADERS.length;
export const NUMBER_HEADERS_WITH_RATING_SCORE =
    MAIN_HEADERS.length + RATING_SCORE_HEADERS.length;
export const NUMBER_HEADERS_INTERIM_ATTESTATION =
    MAIN_HEADERS.length + INTERIM_ATTESTATION_HEADERS.length;
export const NUMBER_HEADERS_WITHOUT_FILTERS = MAIN_HEADERS.length;

export const COLUMN_WIDTH_WITH_RATING_SCORE_AND_INTERIM_ATTESTATION: Record<
    keyof ICurrentAttestationItem,
    number
> = {
    id: 0,
    subject: 40,
    firstAttestation: 12,
    secondAttestation: 12,
    thirdAttestation: 12,
    premiumPoints: 18,
    examPoints: 20,
    totalPoint: 12,
    controlType: 22,
    assessment: 22,
};

// @ts-ignore
export const COLUMN_WIDTH_WITH_RATING_SCORE: Record<
    keyof ICurrentAttestationItem,
    number
> = {
    id: 0,
    subject: 40,
    firstAttestation: 13,
    secondAttestation: 13,
    thirdAttestation: 13,
    premiumPoints: 18,
    examPoints: 20,
    totalPoint: 15,
};

// @ts-ignore
export const COLUMN_WIDTH_WITH_INTERIM_ATTESTATION: Record<
    keyof ICurrentAttestationItem,
    number
> = {
    id: 0,
    subject: 40,
    controlType: 25,
    assessment: 40,
};
