import userDataReducer from './model/userDataSlice';
import userOrderListReducer from './model/userOrderListSlice';
import userEducationPlanReducer from './model/educationPlanSlice';
import userContractsSliceReducer from './model/userContractsSlice';
import userDiplomInfoSliceReducer from './model/userDiplomInfoSlice';
import userTrainingPlanReducer from './model/userTrainingPlanSlice';
import userPersonalDataReducer from './model/userPersonalDataSlice';

import * as thunks from './model/thunk';

export const personalDataReducer = {
    userDataReducer,
    userOrderListReducer,
    userEducationPlanReducer,
    userContractsSliceReducer,
    userDiplomInfoSliceReducer,
    userTrainingPlanReducer,
    userPersonalDataReducer,
};

export const personalDataThunk = {
    ...thunks,
};
