import { Checkbox } from '@sf/sibgu.package.ui';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'shared/lib/hooks/redux';
import { setFilteredHeaders } from 'entities/currentAttestation/modal/currentAttestationSlice';

export const AttestationFilter = () => {
    const [isCheckedRatingScores, setIsCheckedRatingScores] = useState(true);
    const [isCheckedInterimAttestation, setIsCheckedInterimAttestation] =
        useState(true);
    const dispatch = useAppDispatch();

    //сброс старых фильтров, при загрузке страницы
    useEffect(() => {
        dispatch(
            setFilteredHeaders({
                isRatingScores: true,
                isInterimAttestation: true,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleCheckboxRatingScores = () => {
        setIsCheckedRatingScores((prevState) => {
            dispatch(
                setFilteredHeaders({
                    isRatingScores: !prevState,
                    isInterimAttestation: isCheckedInterimAttestation,
                })
            );
            return !prevState;
        });
    };

    const toggleCheckboxInterimAttestation = () => {
        setIsCheckedInterimAttestation((prevState) => {
            dispatch(
                setFilteredHeaders({
                    isRatingScores: isCheckedRatingScores,
                    isInterimAttestation: !prevState,
                })
            );
            return !prevState;
        });
    };

    return (
        <div className='current-attestation__checkbox-group d-flex al-cent gap-10'>
            <Checkbox
                theme='button'
                label='Баллы по рейтинговой оценке'
                checked={isCheckedRatingScores}
                onClick={toggleCheckboxRatingScores}
            />
            <Checkbox
                theme='button'
                label='Промежуточная аттестация'
                checked={isCheckedInterimAttestation}
                onClick={toggleCheckboxInterimAttestation}
            />
        </div>
    );
};
