import { Navigate, Route, Routes } from 'react-router-dom'
import { Layout } from './components/layout'
import { IntermediateAttestation } from './academicPerformance/intermediateAttestation'
import { CurrentAttestation } from './academicPerformance/currentAttestation'
import { DiplomApplication } from './academicPerformance/diplomApplication'
import { LoginLayout } from 'pages/login/components/loginLayout'
import { Authorization } from 'features/auth/login/ui/authorization'
import { PasswordCreating } from 'features/auth/changePassword/ui/passwordСreating'
import { AuthenticationGuard } from './components/AuthenticationGuard'
import { PersonalInformation } from './personalData/personalInformation'
import { OrdersList } from './personalData/ordresList'
import { Contract } from './personalData/contract'

import { ResultOfPrograms } from './academicPerformance/resultOfPrograms'
import { EducationPlan } from './personalData/educationPlan'

import { ScientificLibrary } from './electronicEducational/scientificLibrary'
import { ElectronicDistEducation } from './electronicEducational/electronicDistEducation'
import { LocalNormActs } from './electronicEducational/localNormActs'
import { OrganizationStandart } from './electronicEducational/organizationStandart'

import { NewsAndEvents } from './newsAndEvents'
import { AdditionalEducation } from './electronicEducational/additionalEducation'

export const Pages = () => {
  return (
    <Routes>
      <Route path='/' element={<AuthenticationGuard />}>
        <Route path='/' element={<Layout />}>
          <Route path='/' element={<Navigate replace to='/personal-data/personal-info' />} />
          <Route path='personal-data'>
            <Route path={'/personal-data/personal-info'} element={<PersonalInformation />} />
            <Route path={'/personal-data/orders-list'} element={<OrdersList />} />
            <Route path={'/personal-data/contract'} element={<Contract />} />
            <Route path='' element={<Navigate to='/personal-data/personal-info' />} />
            <Route path='/personal-data/education-plan' element={<EducationPlan />} />
          </Route>
          <Route path='/academic-performance'>
            <Route path={'/academic-performance/intermediate-attestation'} element={<IntermediateAttestation />} />
            <Route path={'/academic-performance/current-attestation'} element={<CurrentAttestation />} />
            <Route path='' element={<Navigate to='/academic-performance/intermediate-attestation' />} />
            <Route path={'/academic-performance/results-of-programs'} element={<ResultOfPrograms />} />
            <Route path={'/academic-performance/diplom-application'} element={<DiplomApplication />} />
          </Route>
          <Route path='/news-and-events' element={<NewsAndEvents />} />
          <Route
            path='/schedule'
            element={
              window.location.pathname === '/schedule' ? (
                (window.location.href = 'https://timetable.pallada.sibsau.ru/timetable/')
              ) : (
                <div>123</div>
              )
            }
          />
          <Route path='electronic-educational'>
            <Route path='/electronic-educational/scientific-library' element={<ScientificLibrary />} />
            <Route path='/electronic-educational/electronic-dist-education' element={<ElectronicDistEducation />} />
            <Route path='/electronic-educational/local-norm-acts' element={<LocalNormActs />} />
            <Route path='/electronic-educational/organization-standart' element={<OrganizationStandart />} />
            <Route path='/electronic-educational/additional-education' element={<AdditionalEducation />} />
            <Route path='' element={<Navigate to='/electronic-educational/scientific-library' />} />
          </Route>
          <Route path='*' element={<Navigate replace to='/academic-performance/intermediate-attestation' />} />
        </Route>
      </Route>
      <Route element={<LoginLayout />}>
        <Route path='/authorization' element={<Authorization />} />
        <Route path='/password-creating' element={<PasswordCreating />} />
        <Route path='*' element={<Navigate to='/authorization' replace />} />
      </Route>
    </Routes>
  )
}
