import React from 'react'
import './../../electronicEducational.scss'

export const ScientificLibrary: React.FC = () => {
  const subSections = [
    {
      title: 'Электронная библиотека',
      link: 'https://biblioteka.sibsau.ru/jirbis2/index.php',
    },
    {
      title: 'Онлайн заявка на получение читательского билета',
      link: 'http://biblioteka.sibsau.ru/jirbis2/index.php?option=com_irbis&view=irbis&Itemid=369',
    },
    // {
    //   title: 'Moodle Test',
    //   link: 'https://moodletest.sibsau.ru/moodle/auth/oidc/',
    // },
  ]
  const renderSectionList = () =>
    subSections.map((section, index) => (
      <a key={index} rel='noreferrer' href={section.link} target='_blank' className='science-library__item'>
        {section.title}
      </a>
    ))

  return (
    <div className='main-div'>
      <div className='d-flex jst-btw al-cent'>
        <div className='text title title-pos'>Научная библиотека</div>
      </div>

      <div className='spacer-3' />

      <div className='science-library__list'>{renderSectionList()}</div>
    </div>
  )
}
