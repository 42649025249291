import { Tabs } from '@sf/sibgu.package.ui'
import { ITab } from '@sf/sibgu.package.ui/dist/types/components/Tabs/tabs'
import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux'
import { SEMESTER_TITLE_LIST } from 'shared/consts/lists'
import { setCurrentSemesterList, setSelectedIndex, setSelectedSemester } from 'shared/model/reducers/semesterListSlice'
import { fetchSemesterList } from 'shared/model/actionCreators/semesterList'

interface SemesterListTabsProps {
  children?: React.ReactNode | React.ReactNode[]
  className?: string
  onTabChange?(value: number): void
}

export const SemesterListTabs: React.FC<SemesterListTabsProps> = (props) => {
  const { children, className, onTabChange } = props

  const { currentSpecialty } = useAppSelector((state) => state.choiceSpecialtyReducer)

  const {
    creditBookList: semesterList,
    currentSemesterList,
    selectedIndex,
    selectedSemesterId,
  } = useAppSelector((state) => state.semesterListReducer)

  const [selectedTab, setSelectedTab] = useState<number>(0)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (currentSemesterList.length && selectedSemesterId) {
      const idx = currentSemesterList.findIndex((item) => item.id === selectedSemesterId)
      if (idx !== -1) {
        setSelectedTab(idx)
      }
    }
  }, [currentSemesterList, selectedSemesterId])

  useEffect(() => {
    dispatch(setSelectedIndex(selectedTab))
    onTabChange?.(selectedTab)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab])

  // если выбрана специализация загружаем список семестров
  useEffect(() => {
    if (!semesterList.length) {
      dispatch(fetchSemesterList())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [semesterList])

  // если список семестров загружен выбираем нужный исходя из выбранной специализации
  useEffect(() => {
    if (semesterList.length && currentSpecialty) {
      const selectedSemestersWithSpeciality = semesterList.find(
        (item) => item.creditbook === currentSpecialty.creditbook
      )
      if (selectedSemestersWithSpeciality?.semesterList.length) {
        dispatch(setCurrentSemesterList(selectedSemestersWithSpeciality.semesterList))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [semesterList, currentSpecialty])

  const changeTabHandler = useCallback(
    (index: number) => {
      const newSelectedSemesterId = currentSemesterList[index].id
      dispatch(setSelectedSemester(newSelectedSemesterId))
      setSelectedTab(index)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSemesterList]
  )

  const tabs = useMemo<ITab[]>(() => {
    return currentSemesterList?.map((item) => ({
      key: item.id,
      title: SEMESTER_TITLE_LIST[item.label],
    }))
  }, [currentSemesterList])

  return (
    <Tabs className={className} items={tabs} selected={selectedTab} children={children} onSelect={changeTabHandler} />
  )
}
