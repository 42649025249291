import { ITableHeader } from '@sf/sibgu.package.ui/dist/types/components/Table/types'
import { IIntermediateAttestationItem } from 'entities/intermediateAttestation/modal/intermediateAttestationSlice'
import tableArrow from 'shared/assets/educationPlan/arrow.svg'

interface IAttestationHeaderProps {
  editTableTabs: () => void
  editSecondTabs: () => void
  buttonActive: {
    first: boolean
    second: boolean
  }
}

export const WIDTH = {
  discipline: 35,
  formcontrol: 13,
  zet: 10,
  hours: 10,
  contacthours: 15,
  'Лек.': 10,
  'Прак.': 10,
  'Лаб.': 10,
  SRShours: 10,
  rightArrow: 4,
  secondArrow: 5,
}

export const INTERIM_ATTESTATION_TABLE_HEADERS = (
  props: IAttestationHeaderProps
): ITableHeader<keyof IIntermediateAttestationItem>[] => {
  return [
    {
      name: 'Наименование дисциплин (модулей), практик',
      value: 'discipline',
    },
    {
      name: 'Форма контроля',
      value: 'formcontrol',
    },
    { name: 'З.Е.', value: 'zet' },
    { name: 'Часы', value: 'hours' },
    {
      name: (
        <div>
          <span>Контактная работа</span>
        </div>
      ) as unknown as any,
      value: 'contacthours',
      className: 'light-blue',
    },
    { name: 'Лек.', value: 'Лек.', className: 'blue' },
    { name: 'Прак.', value: 'Прак.', className: 'blue' },
    { name: 'Лаб.', value: 'Лаб.', className: 'blue' },
    {
      name: (
        <div>
          <button className='arrow-btn' onClick={() => props.editSecondTabs()}>
            <img
              className={props.buttonActive.second ? 'arrow-btn--activated' : 'arrow-btn--deactivated'}
              src={tableArrow}
              alt=''
            />
          </button>
        </div>
      ) as unknown as any,
      value: 'secondArrow',
      className: props.buttonActive.second ? 'blue' : 'light-blue',
    },
    { name: 'СРС', value: 'SRShours', className: 'light-blue' },
    {
      name: (
        <button className='arrow-btn' onClick={() => props.editTableTabs()}>
          <img
            className={props.buttonActive.first ? 'arrow-btn--activated' : 'arrow-btn--deactivated'}
            src={tableArrow}
            alt=''
          />
        </button>
      ) as unknown as any,
      value: 'rightArrow',
    },
  ] as ITableHeader<keyof IIntermediateAttestationItem>[]
}
