import React from 'react';
import './../../electronicEducational/electronicEducational.scss';
export const NewsAndEvents: React.FC = () => {
    const subSections = [
        {
            title: 'Официальная группа Вконтакте',
            link: 'https://vk.com/sibgu_ru',
        },
        {
            title: 'Официальный Telegram-канал',
            link: 'https://t.me/reshetnevuniversity',
        },
        {
            title: 'Дзен',
            link: 'https://dzen.ru/reshu_ru ',
        },
        {
            title: 'RuTube',
            link: 'https://rutube.ru/channel/25502476/ ',
        },
        {
            title: 'Официальный сайт университета',
            link: 'https://www.sibsau.ru/',
        },
        {
            title: 'Одноклассники',
            link: 'https://ok.ru/group/62522772488402',
        },
    ];
    const renderSectionList = () =>
        subSections.map((section, index) => (
            <a
                key={index}
                rel='noreferrer'
                href={section.link}
                target='_blank'
                className='science-library__item'>
                {section.title}
            </a>
        ));
    return (
        <div className='main-div'>
            <div className='d-flex jst-btw al-cent'>
                <div className='text title title-pos'>
                    Новости и события Университета
                </div>
            </div>
            <div className='spacer-3' />
            <div className='science-library__list'>{renderSectionList()}</div>
        </div>
    );
};
