import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchLogin } from './login';

interface ILogin {
    isFirstAuthorization: boolean;
    //  token: null | string;
    loading: boolean;
    error: null | string;
}

const initialState: ILogin = {
    isFirstAuthorization: false,
    // token: null,
    loading: false,
    error: null,
};

export const loginSlice = createSlice({
    name: 'loginSlice',
    initialState,
    reducers: {
        setError: (state, { payload: error }: PayloadAction<string>) => {
            state.error = error;
        },
    },
    extraReducers: {
        [fetchLogin.pending.type]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [fetchLogin.fulfilled.type]: (state, action: PayloadAction<ILogin>) => {
            state.isFirstAuthorization = action.payload.isFirstAuthorization;
            //state.token = action.payload.token;
            state.loading = false;
            state.error = '';
        },
        [fetchLogin.rejected.type]: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { setError } = loginSlice.actions;
export default loginSlice.reducer;
