import * as Yup from 'yup'
// import { PHONE } from 'shared/consts/regex'
import {
  EMAIL_FIELD,
  // PHONE_FIELD
} from 'shared/consts/constants'

export const formValidationContactDetails = Yup.object({
  Email: Yup.string().email(EMAIL_FIELD),
  // Phone: Yup.string().matches(PHONE, PHONE_FIELD),
})
