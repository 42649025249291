import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchCurrentAttestation } from 'entities/currentAttestation/modal/currentAttestation'
import { ITableHeader } from '@sf/sibgu.package.ui/dist/types/components/Table/types'
import { CURRENT_ATTESTATION_TABLE_HEADERS } from 'entities/currentAttestation/consts/currentAttestationLists'
import { fromCurrentAttestationHeadersToFilteredHeaders } from 'entities/currentAttestation/mappers/fromCurrentAttestationHeadersToFilteredHeaders'

export interface ICurrentAttestationItem {
  id: number
  subject: string
  firstAttestation?: number
  secondAttestation?: number
  thirdAttestation?: number
  premiumPoints?: number
  examPoints?: number
  totalPoint?: number
  controlType: string
  assessment?: string
}

export interface ICurrentAttestation {
  headers: ITableHeader<keyof ICurrentAttestationItem>[]
  data: ICurrentAttestationItem[][] | []
  loading: boolean
  error: null | string
}

export interface IHeaderFilters {
  isRatingScores: boolean
  isInterimAttestation: boolean
}

const initialState: ICurrentAttestation = {
  headers: CURRENT_ATTESTATION_TABLE_HEADERS,
  data: [],
  loading: false,
  error: null,
}

export const currentAttestationSlice = createSlice({
  name: 'currentAttestationSlice',
  initialState,
  reducers: {
    setFilteredHeaders: (state, { payload }: PayloadAction<IHeaderFilters>) => {
      state.headers = fromCurrentAttestationHeadersToFilteredHeaders(
        payload.isRatingScores,
        payload.isInterimAttestation
      )
    },
    clearCurrentAttestationData: (state) => {
      state.data = []
    },
  },
  extraReducers: {
    [fetchCurrentAttestation.pending.type]: (state) => {
      state.loading = true
      state.error = null
    },
    [fetchCurrentAttestation.fulfilled.type]: (state, action: PayloadAction<ICurrentAttestation>) => {
      // @ts-ignore
      state.data[action.payload.index] =
        // @ts-ignore
        action.payload.data.currentAttestationList
      state.loading = false
      state.error = ''
    },
    [fetchCurrentAttestation.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const { setFilteredHeaders, clearCurrentAttestationData } = currentAttestationSlice.actions
export default currentAttestationSlice.reducer
