import React from 'react'
import { Skeleton } from '@sf/sibgu.package.ui'
import { getTableSkeleton } from 'shared/lib/utils/getTableSkeleton'

export const SkeletonIntermediateAttestation = () => {
  return (
    <div className='main-cont'>
      <div className='text title intermediate-attestation__header'>Результаты промежуточной аттестации</div>
      <Skeleton height={56} className='current-attestation__select' />
      <div className='intermediate-attestation__body'>
        <Skeleton height={36} />
        {getTableSkeleton(3, 6)}
      </div>
    </div>
  )
}
