import { createAsyncThunk } from '@reduxjs/toolkit';
import { getInterAttestation } from 'shared/api/interAttestation';

interface IFetchIntermediateAttestation {
    specialityId: string;
    semesterId: string;
    index: number;
    creditBookId: string;
}

export const fetchIntermediateAttestation = createAsyncThunk(
    'intermediateAttestation/fetchAll',
    async ({ specialityId, semesterId, index, creditBookId }: IFetchIntermediateAttestation, thunkAPI) => {
        try {
            const response = await getInterAttestation(specialityId, semesterId, creditBookId);
            return { data: response.data, index };
        } catch (e) {
            return thunkAPI.rejectWithValue('Ошибка получения данных.');
        }
    },
);