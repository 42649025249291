import React from 'react';
import './../../electronicEducational.scss';
export const LocalNormActs: React.FC = () => {
    const subSections = [
        {
            title: 'Локальные нормативные акты Университета',
            link: 'https://www.sibsau.ru/page/local-regulations-other-documents',
        },
    ];
    const renderSectionList = () =>
        subSections.map((section, index) => (
            <a
                key={index}
                rel='noreferrer'
                href={section.link}
                target='_blank'
                className='science-library__item'>
                {section.title}
            </a>
        ));
    return (
        <div className='main-div'>
            <div className='d-flex jst-btw al-cent'>
                <div className='text title title-pos'>
                    Локальные нормативные акты
                </div>
            </div>
            <div className='spacer-3' />
            <div className='science-library__list'>{renderSectionList()}</div>
        </div>
    );
};
