import { useEffect, useMemo, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { ICompetenceList } from '@sf/sibgu.package.ui/dist/types/components/CompetenceChart/CompetenceChart'
import { IOptionItem } from '@sf/sibgu.package.ui/dist/types/types/select'
import { TProgressBarColor } from '@sf/sibgu.package.ui/dist/types/components/VerticalProgressBar/VerticalProgressBar'
import { Select, CompetenceChart } from '@sf/sibgu.package.ui'
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux'
import { BottomBlockWithInfoAboutSubjects } from '../components/bottomBlockWithInfoAboutSubjects'
import { ChoiceSpecialty } from 'features/choiceSpecialty/ui'
import { PageSkeleton } from 'shared/ui/PageSkeleton'
import { fetchTrainingPlanInfo } from 'entities/personalData/model/thunk'
import { ISubject } from '../../lib/types'
import { BottomBlockWithInfoAboutCompetence } from '../components/bottomBlockWithInfoAboutCompetence'
import './resultOfPrograms.scss'

interface IShowFullCompentence {
  isShow: boolean
  subjects: ISubject[]
  name: string
  percentage: number
}
interface IShowOneCompentence {
  isShow: boolean
  subjectName: string
  name: string
  percentage: number
  disToCompEffect: number
  dispIsDone: boolean
}

const fullInitalState: IShowFullCompentence = {
  isShow: false,
  subjects: [],
  name: '',
  percentage: 0,
}

const oneInitalState: IShowOneCompentence = {
  isShow: false,
  subjectName: '',
  dispIsDone: false,
  name: '',
  percentage: 0,
  disToCompEffect: 0,
}

export const ResultOfPrograms = () => {
  const { currentSpecialty, loading: specialityLoading } = useAppSelector((state) => state.choiceSpecialtyReducer)
  const { data, loading } = useAppSelector((state) => state.userTrainingPlanReducer)
  const dispatch = useAppDispatch()

  const [showFullCompetence, setShowFullCompetence] = useState<IShowFullCompentence>(fullInitalState)
  const [showOneCompetence, setShowOneCompetence] = useState<IShowOneCompentence>(oneInitalState)
  const [selectedDisciplineFilter, setSelectedDisciplineFilter] = useState<IOptionItem>({
    id: '1',
    title: 'Все дисциплины',
  })

  useEffect(() => {
    if (currentSpecialty?.creditbook) {
      dispatch(fetchTrainingPlanInfo(currentSpecialty.creditbook))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpecialty])

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit: () => {},
  })

  const compenteceList = useMemo<ICompetenceList[]>(() => {
    if (selectedDisciplineFilter.id === '1') {
      return data.map((item) => ({
        id: item.code,
        title: item.code,
        percentage: item.developmentLevel,
      }))
    }

    return data
      .filter((item) => item.disciplines.some((dis) => dis.name === selectedDisciplineFilter.id))
      .map((item) => ({
        id: item.code,
        title: item.code,
        percentage: item.developmentLevel,
      }))
  }, [data, selectedDisciplineFilter])

  const disciplinesList = useMemo<IOptionItem[]>(() => {
    const uniqueDisciplines = new Set<string>()
    uniqueDisciplines.add('1')
    data.forEach((item) => item.disciplines.forEach((dis) => uniqueDisciplines.add(dis.name)))
    const list: IOptionItem[] = []
    uniqueDisciplines.forEach((item) =>
      list.push({
        id: item,
        title: item === '1' ? 'Все дисциплины' : item,
      })
    )
    return list.sort((a, b) => a.id.localeCompare(b.id))
  }, [data])

  const listColor = useMemo<TProgressBarColor>(() => {
    if (selectedDisciplineFilter.id !== '1') {
      const selectedComp = data.find((item) => item.disciplines.some((dis) => dis.name === selectedDisciplineFilter.id))
      const selectedDisc = selectedComp?.disciplines.find((item) => item.name === selectedDisciplineFilter.id)
      switch (selectedDisc?.mastered) {
        case true:
          return 'green'
        case false:
          return 'red'
        default:
          return 'blue'
      }
    }
    return 'blue'
  }, [data, selectedDisciplineFilter])

  const selectCompetenceHandler = (code: string) => {
    const selectedCompetence = data.find((item) => item.code === code)
    if (selectedCompetence) {
      if (selectedDisciplineFilter.id === '1') {
        setShowFullCompetence({
          isShow: true,
          subjects: selectedCompetence.disciplines.map((item) => ({
            title: item.name,
            subjectDone: item.mastered,
          })),
          name: selectedCompetence.name,
          percentage: selectedCompetence.developmentLevel,
        })
      } else {
        const selectedDiscipline = selectedCompetence.disciplines.find(
          (item) => item.name === selectedDisciplineFilter.id
        )
        if (selectedDiscipline) {
          setShowOneCompetence({
            isShow: true,
            subjectName: selectedDisciplineFilter.title,
            name: selectedCompetence.name,
            dispIsDone: selectedDiscipline.mastered,
            percentage: selectedCompetence.developmentLevel,
            disToCompEffect: selectedDiscipline.weightInCompetence,
          })
        }
      }
    } else {
      setShowFullCompetence(fullInitalState)
      setShowOneCompetence(oneInitalState)
    }
  }

  const onChangeDisciplineHandler = (_name: string, value: IOptionItem) => {
    if (!value) {
      setSelectedDisciplineFilter(disciplinesList[0])
    } else {
      setSelectedDisciplineFilter(value)
    }
  }

  if (loading || specialityLoading) {
    return <PageSkeleton title='Результаты освоения программы' />
  }

  return (
    <div className='main-div'>
      <div className='text title'>Результаты освоения программы</div>

      <div className='spacer-3' />

      <div className='result-of-programs__forms'>
        <ChoiceSpecialty />

        <FormikProvider value={formik}>
          <Select
            popoverClassName='select-with-overflow'
            label='Дисциплина'
            name='discipline'
            optionList={disciplinesList}
            chosenValue={selectedDisciplineFilter}
            setFieldValue={onChangeDisciplineHandler}
          />
        </FormikProvider>
      </div>

      <div className='spacer-3' />

      <div className='result-of-programs__competence-diagram'>
        <CompetenceChart list={compenteceList} color={listColor} onSelect={selectCompetenceHandler} />
      </div>

      <div className='spacer-3' />

      {(showFullCompetence.isShow || showOneCompetence.isShow) &&
        (selectedDisciplineFilter.id === '1' ? (
          <BottomBlockWithInfoAboutSubjects
            subjects={showFullCompetence.subjects}
            compDonePerc={showFullCompetence.percentage}
            compName={showFullCompetence.name}
          />
        ) : (
          <BottomBlockWithInfoAboutCompetence
            compName={showOneCompetence.name}
            compDonePerc={showOneCompetence.percentage}
            dispIsDone={showOneCompetence.dispIsDone}
            disToCompEffect={showOneCompetence.disToCompEffect}
            nameOfSubject={showOneCompetence.subjectName}
          />
        ))}
    </div>
  )
}
