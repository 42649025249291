import axiosInstance from './interceptors';

export const setPassword = (token: string, newPassword: string) => {
    return axiosInstance.post('/setPassword', {
        token,
        newPassword,
    });
};

export const changePassword = (
    token: string,
    password: string,
    newPassword: string
) => {
    return axiosInstance.post('/changePassword', {
        token,
        password,
        newPassword,
    });
};
