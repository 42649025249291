import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchSemesterList } from 'shared/model/actionCreators/semesterList'

export interface ISemester {
  label: string
  id: string
  isFuturePeriod: boolean
}

export interface ISemesterListItem {
  creditbook: string
  semesterList: ISemester[]
}

export interface ISemesterList {
  creditBookList: ISemesterListItem[]
  currentSemesterList: ISemester[]
  selectedSemesterId: string
  selectedIndex: number
  loading: boolean
  error: null | string
}

const initialState: ISemesterList = {
  creditBookList: [],
  loading: false,
  currentSemesterList: [],
  selectedSemesterId: '',
  selectedIndex: 0,
  error: null,
}

export const semesterListSlice = createSlice({
  name: 'semesterListSlice',
  initialState,
  reducers: {
    setCurrentSemesterList(state, action: PayloadAction<ISemester[]>) {
      state.currentSemesterList = action.payload
      state.selectedSemesterId = action.payload[0].id
    },
    setSelectedIndex(state, action: PayloadAction<number>) {
      state.selectedIndex = action.payload
    },
    setSelectedSemester(state, action: PayloadAction<string>) {
      state.selectedSemesterId = action.payload
    },
  },
  extraReducers: {
    [fetchSemesterList.pending.type]: (state) => {
      state.loading = true
      state.error = null
    },
    [fetchSemesterList.fulfilled.type]: (state, action: PayloadAction<ISemesterList>) => {
      state.creditBookList = action.payload.creditBookList
      state.loading = false
      state.error = ''
    },
    [fetchSemesterList.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const { setCurrentSemesterList, setSelectedSemester, setSelectedIndex } = semesterListSlice.actions

export default semesterListSlice.reducer
