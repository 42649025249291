import { ICurrentAttestationItem } from 'entities/currentAttestation/modal/currentAttestationSlice';
import { ITableHeader } from '@sf/sibgu.package.ui/dist/types/components/Table/types';
import {
    INTERIM_ATTESTATION_HEADERS,
    MAIN_HEADERS,
    RATING_SCORE_HEADERS,
} from 'entities/currentAttestation/consts/currentAttestationLists';

export const fromCurrentAttestationHeadersToFilteredHeaders = (
    isRatingScores: boolean,
    isInterimAttestation: boolean
): ITableHeader<keyof ICurrentAttestationItem>[] => {
    let filteredHeaders: ITableHeader<keyof ICurrentAttestationItem>[] = [
        ...MAIN_HEADERS,
    ];

    if (isRatingScores) {
        filteredHeaders.push(...RATING_SCORE_HEADERS);
    }

    if (isInterimAttestation) {
        filteredHeaders.push(...INTERIM_ATTESTATION_HEADERS);
    }

    return filteredHeaders;
};
