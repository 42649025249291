import React from 'react'
import './../../electronicEducational.scss'
export const AdditionalEducation: React.FC = () => {
  const subSections = [
    {
      title: 'Информация по программам дополнительного образования',
      link: 'https://dpo.sibsau.ru/blog/3',
    },
    {
      title: 'Форма заявления на зачисление на программу дополнительного образования',
      // subtitle:
      //     'Информация о режиме работы, ресурсах, услугах, сервисах и т.п.',
      link: '',
    },
    {
      title: 'Программы проф. переподготовки',
      // subtitle:
      //     'Информация о режиме работы, ресурсах, услугах, сервисах и т.п.',
      link: '',
    },
  ]
  const renderSectionList = () =>
    subSections.map((section, index) =>
      section.link ? (
        <a key={index} rel='noreferrer' href={section.link} target='_blank' className='science-library__item'>
          {section.title}
        </a>
      ) : (
        <span className='science-library__item'>{section.title}</span>
      )
    )
  return (
    <div className='main-div'>
      <div className='d-flex jst-btw al-cent'>
        <div className='text title title-pos'>Дополнительное образование</div>
      </div>
      <div className='spacer-3' />
      <div className='science-library__list'>{renderSectionList()}</div>
    </div>
  )
}
