import { createSlice } from '@reduxjs/toolkit'
import { IDiplomInfo } from 'shared/api/personalData/types'
import { fetchConfirmUserDiplomInfo, fetchUserDiplomInfo } from './thunk'

export interface IUserDiplomInfoSlice {
  data: IDiplomInfo | null
  loading: boolean
  confirmLoading: boolean
}
const initialState: IUserDiplomInfoSlice = {
  data: null,
  loading: false,
  confirmLoading: false,
}

export const userDiplomInfoSlice = createSlice({
  name: 'userDiplomInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserDiplomInfo.fulfilled, (state, action) => {
      state.data = action.payload
      state.loading = false
    })
    builder.addCase(fetchUserDiplomInfo.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchUserDiplomInfo.rejected, (state) => {
      state.data = null
      state.loading = false
    })

    builder.addCase(fetchConfirmUserDiplomInfo.pending, (state) => {
      state.confirmLoading = true
    })
    builder.addCase(fetchConfirmUserDiplomInfo.rejected, (state) => {
      state.confirmLoading = false
    })
    builder.addCase(fetchConfirmUserDiplomInfo.fulfilled, (state) => {
      state.confirmLoading = false
    })
  },
})

export default userDiplomInfoSlice.reducer
