import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSetPassword } from '../actionCreators/setPassword';

interface ISetPasswordState {
    loading: boolean;
    error: null | string;
}

const initialState: ISetPasswordState = {
    loading: false,
    error: null,
};

export const setPasswordSlice = createSlice({
    name: 'setPasswordSlice',
    initialState,
    reducers: {
        clearSetPasswordData: (state) => {
            state.loading = false;
            state.error = null;
        },
    },
    extraReducers: {
        [fetchSetPassword.pending.type]: (state) => {
            state.loading = true;
        },
        [fetchSetPassword.fulfilled.type]: (state) => {
            state.loading = false;
            state.error = '';
        },
        [fetchSetPassword.rejected.type]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { clearSetPasswordData } = setPasswordSlice.actions;
export default setPasswordSlice.reducer;
