import axiosInstance from 'shared/api/interceptors'
import { AxiosDataResponse } from 'shared/types/apiTypes'
import {
  ICompetenceDTO,
  IContractsDTO,
  IDiplomInfo,
  IOrderListDTO,
  ITrainingPlanDTO,
  IUserDataDTO,
  IUserInfo,
  RequestFetchAPIUpdateUserInfo,
  RequestGetUserTrainingPlan,
  ResponseGetAddress,
} from './types'

// Получение основной личной информации пользователя по его идентификатору
export const getUserData = (): Promise<AxiosDataResponse<IUserDataDTO>> => {
  try {
    return axiosInstance.get('/getUserData')
  } catch (err) {
    return Promise.reject(err)
  }
}

// Получение списка приказов по зачетной книге
export const getUserOrderList = (creditBookId: string): Promise<AxiosDataResponse<IOrderListDTO>> => {
  try {
    return axiosInstance.get('/getorderList', { params: { creditBookId } })
  } catch (err) {
    return Promise.reject(err)
  }
}

// Получение информации об учебном плане студента
export const getUserTrainingPlan = ({
  creditBookId,
  semesterId,
}: RequestGetUserTrainingPlan): Promise<AxiosDataResponse<ITrainingPlanDTO>> => {
  try {
    return axiosInstance.get('/getTrainingPlanData', {
      params: { creditBookId, semesterId },
    })
  } catch (err) {
    return Promise.reject(err)
  }
}
// Получение сведений о договорах студента
export const getUserContractsData = (): Promise<AxiosDataResponse<IContractsDTO>> => {
  try {
    return axiosInstance.get('/getContractsData')
  } catch (err) {
    return Promise.reject(err)
  }
}

// Получение сведений по освоению компетенций учебного плана
export const getUserDevelopmentTrainingPlan = (creditBookId: string): Promise<AxiosDataResponse<ICompetenceDTO>> => {
  try {
    return axiosInstance.get('/getDevelopmentTrainingPlan', {
      params: { creditBookId },
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// Получение информации о дипломе
export const getUserDiplomInfo = (creditBookId: string): Promise<AxiosDataResponse<IDiplomInfo>> => {
  try {
    return axiosInstance.get('/diplomaInfo', {
      params: { creditBookId },
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getAddress = (Level: number, ParentGuid?: string): Promise<AxiosDataResponse<ResponseGetAddress[]>> => {
  try {
    const params: { Level: number; ParentGuid?: string } = {
      Level,
    }
    if (ParentGuid) {
      params.ParentGuid = ParentGuid
    }
    return axiosInstance.get(`/addressPart`, { params: { ...params } })
  } catch (err) {
    return Promise.reject(err)
  }
}

// Получение данных контактной информации
export const fetchAPIUserInfo = async (): Promise<AxiosDataResponse<IUserInfo>> => {
  try {
    return await axiosInstance.get(`/contactInfo`)
  } catch (err) {
    return Promise.reject(err)
  }
}

//? POST
// Проставление статуса подтверждения диплома со стороны студента
export const confirmUserDiplomInfo = (guidDiplom: string): Promise<AxiosDataResponse<IDiplomInfo>> => {
  try {
    return axiosInstance.post(`/diplomaInfo?GuidDiploma=${guidDiplom}`)
  } catch (err) {
    return Promise.reject(err)
  }
}
// Отправка данных по физлицу
export const fetchAPIUpdateUserInfo = async (data: RequestFetchAPIUpdateUserInfo): Promise<void> => {
  try {
    const res: void = await axiosInstance.post('/contactInfo', data)
    return res
  } catch (err) {
    return Promise.reject(err)
  }
}
