import { FC } from 'react';
import { Button, Modal } from '@sf/sibgu.package.ui';
import './modalPasswordRecovery.scss';

interface ModalPasswordRecoveryProps {
    active: boolean;
    onClose(): void;
}

export const ModalPasswordRecovery: FC<ModalPasswordRecoveryProps> = (
    props
) => {
    const { active, onClose } = props;
    return (
        <Modal
            active={active}
            onClose={onClose}
            className='modal-password-recovery'>
            <div className='modal-password-recovery__title text bold fz-24'>
                Восстановление пароля
            </div>
            <div className='modal-password-recovery__text'>
                Свежитесь со <b>Службой поддержки</b> для восстановления пароля
                по номеру <b>+7 900 000 0000</b>
            </div>

            <Button
                text='Спасибо'
                className='modal-password-recovery__button'
                onClick={onClose}
            />
        </Modal>
    );
};
