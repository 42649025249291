import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TOption } from '@sf/sibgu.package.ui/dist/types/components/InputWithSelects/inputWithSelects'
import { ResponseGetAddress } from 'shared/api/personalData/types'
import { getAddressStateValue, initialAddressValue, resetLists } from '../lib/helpers'
import { fetchUserContactInfo } from './thunk'

export enum AddressFieldsEnum {
  Country = 0,
  Region = 1,
  District = 3,
  City = 4,
  Locality = 6,
  Territory = 65,
  Street = 7,
  House = 100,
}

export interface IPersonalDataAddress extends Record<keyof typeof AddressFieldsEnum, ResponseGetAddress> {
  Appartament?: string
  Index?: string
}

export interface IPersonalDataForm {
  Email: string
  Phone: string
  Address: IPersonalDataAddress
}

export type TLists = Record<keyof Omit<IPersonalDataAddress, 'Index'>, TOption<ResponseGetAddress>[] | null>

export interface IUserPersonalDataSlice {
  loading: boolean
  lists: TLists
  form: IPersonalDataForm
}

export const listsInitialValue: TLists = {
  Country: [],
  Region: [],
  District: [],
  City: [],
  Locality: [],
  Territory: [],
  Street: [],
  House: [],
  Appartament: [],
}

export const formInitialState: IPersonalDataForm = {
  Email: '',
  Phone: '',
  Address: {
    Country: initialAddressValue,
    Region: initialAddressValue,
    District: initialAddressValue,
    City: initialAddressValue,
    Locality: initialAddressValue,
    Territory: initialAddressValue,
    Street: initialAddressValue,
    House: initialAddressValue,
    Appartament: '',
    Index: '',
  },
}

const initialState: IUserPersonalDataSlice = {
  loading: false,
  lists: listsInitialValue,
  form: formInitialState,
}

export const userPersonalDataSlice = createSlice({
  name: 'userOrderList',
  initialState,
  reducers: {
    changeAddressField(
      state,
      action: PayloadAction<{
        field: keyof IPersonalDataAddress
        value: ResponseGetAddress
      }>
    ) {
      if (action.payload.field !== 'Index' && action.payload.field !== 'Appartament') {
        state.form.Address[action.payload.field] = action.payload.value as ResponseGetAddress
      }
      if (action.payload.field === 'House') {
        state.form.Address.Appartament = action.payload.value?.BuildingNumber || ''
        state.form.Address.Index = action.payload.value?.Index || ''
      }

      state.form.Address = getAddressStateValue(state.form.Address, action.payload.field)
      state.lists = resetLists(state.lists, action.payload.field)
    },
    changeFormField(
      state,
      action: PayloadAction<{
        field: keyof Omit<IPersonalDataForm, 'Address'>
        value: string
      }>
    ) {
      state.form[action.payload.field] = action.payload.value
    },
    setList(
      state,
      action: PayloadAction<{
        field: keyof TLists
        value: ResponseGetAddress[]
      }>
    ) {
      state.lists[action.payload.field] = action.payload?.value?.map((item) => ({
        title: item.Name,
        value: item.Guid,
        meta: item,
      }))
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserContactInfo.fulfilled, (state, action) => {
      state.form = action.payload
      state.loading = false
    })
    builder.addCase(fetchUserContactInfo.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(fetchUserContactInfo.pending, (state) => {
      state.loading = true
    })
  },
})

export const { changeAddressField, changeFormField, setList } = userPersonalDataSlice.actions

export default userPersonalDataSlice.reducer
