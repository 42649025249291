import React from 'react'
import { Table, ButtonIcon, EvaluationsChartModal, Button, Tag } from '@sf/sibgu.package.ui'
import { useEffect, useState } from 'react'
import { getSortedTableDate } from 'shared/lib/utils/sorting'
import {
  DIPLOM_ATTESTATION_LIST_COLUMN_WIDTH,
  DIPLOM_ATTESTATION_TABLE_HEADERS,
} from 'entities/diplomAttestation/lib/const'
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux'
import { VerticalHr } from 'shared/ui/verticalHr/VerticalHr'
import studentHatEmojiPath from 'shared/assets/diplomApplication/studentHatEmoji.svg'
import infoIconPath from 'shared/assets/diplomApplication/infoIcon.svg'
import { ChoiceSpecialty } from 'features/choiceSpecialty/ui'
import { PageSkeleton } from 'shared/ui/PageSkeleton'
import { fetchConfirmUserDiplomInfo, fetchUserDiplomInfo } from 'entities/personalData/model/thunk'
import './diplomApplication.scss'
import { fetchChoiceSpecialty } from 'features/choiceSpecialty/modal/choiceSpecialty'
import useTableHeight from 'shared/hooks/useTableHeight'

export const DiplomApplication = () => {
  const [modalAverageModalActive, setModalAverageModalActive] = useState(false)
  const { data, loading, confirmLoading } = useAppSelector((state) => state.userDiplomInfoSliceReducer)

  const { currentSpecialty } = useAppSelector((state) => state.choiceSpecialtyReducer)

  const dispatch = useAppDispatch()

  const tableHeight = useTableHeight({
    withTimer: true,
  })

  useEffect(() => {
    dispatch(fetchChoiceSpecialty())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currentSpecialty) {
      dispatch(fetchUserDiplomInfo(currentSpecialty.creditbook))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpecialty])

  const onOpenAverageModalHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    setModalAverageModalActive(true)
  }

  const onCloseAverageModalHandler = () => {
    setModalAverageModalActive(false)
  }

  const onConfirmClickHandler = () => {
    dispatch(fetchConfirmUserDiplomInfo(data?.guidDiploma || '')).then(() => {
      dispatch(fetchUserDiplomInfo(currentSpecialty?.creditbook || ''))
    })
  }

  // if (loading) {
  //   return <PageSkeleton title='Приложение к диплому' />
  // }

  return (
    <div className='main-cont'>
      <div className='d-flex jst-btw al-cent'>
        <div className='text title'>Приложение к диплому</div>
        <div>
          <div className='status-div d-flex al-cent gap-16 '>
            {data && !data?.confirm && (
              <>
                <Button
                  isLoading={confirmLoading}
                  theme='text'
                  text='Подтверждаю'
                  size='lg'
                  className='confirmed_btn'
                  onClick={onConfirmClickHandler}
                />
                <VerticalHr />
              </>
            )}

            <Tag theme={data?.confirm ? 'confirmed' : 'draft'} text={data?.confirm ? 'Подтверждено' : 'Черновик'} />
          </div>
        </div>
      </div>

      <div className='spacer-3' />

      <ChoiceSpecialty />

      <div className='spacer-3' />

      <div className='d-flex jst-btw al-cent'>
        <div className='d-flex al-cent gap-11'>
          <img src={studentHatEmojiPath} alt='' />

          <p className='text semi-bold fz-20'>Средний балл {data?.averageScore}*</p>

          {data && (
            <button type='button' onClick={onOpenAverageModalHandler} className='info-btn'>
              <img src={infoIconPath} alt='' />
            </button>
          )}
        </div>
        {/* <div className='d-flex jst-arnd'>
          <ButtonIcon icon='download' size='sm' />
        </div> */}
      </div>

      <div className='spacer-1' />

      <Table
        bodyHeight={`${tableHeight}px`}
        loading={loading}
        dataTable={data?.disciplines || []}
        headers={DIPLOM_ATTESTATION_TABLE_HEADERS}
        columnWidth={DIPLOM_ATTESTATION_LIST_COLUMN_WIDTH}
        sortTableData={getSortedTableDate}
      />

      <div className='spacer-3' />

      <p className='text grey italic fz-14'>
        * Подсчет среднего балла в соответствии с Приказом Министерства науки и высшего образования РФ от 27 июля 2021
        г. №670 “Об утверждении Порядка заполнения, учета и выдачи документов о высшем образовании и о квалификации,
        приложений к ним и их дубликатов”.
        <br /> По вопросам, связанным с приложением к диплому и подсчетом среднего бала обращаться в дирекцию института.
      </p>

      <EvaluationsChartModal
        marks={{
          perfect: data?.excellent,
          good: data?.good,
          satisfactory: data?.satisfactory,
        }}
        active={modalAverageModalActive}
        onClose={onCloseAverageModalHandler}
      />
    </div>
  )
}
