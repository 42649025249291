import { Skeleton } from '@sf/sibgu.package.ui';
import React from 'react';

const getHeaderTableSkeleton = (arr: number[]) => {
    return (
        <thead className='get-header-table-skeleton__thead'>
            <tr className='get-header-table-skeleton_tr'>
                {[...arr].map((_) => {
                    return (
                        <th className='get-header-table-skeleton_th'>
                            <Skeleton height={52} />
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

const getRowSkeleton = (arr: number[], index: string) => {
    return (
        <tr key={index} className='get-header-table-skeleton_tr'>
            {[...arr].map((_, i) => {
                return (
                    <td
                        className='get-header-table-skeleton_td'
                        key={`col-${i}`}>
                        <Skeleton height={52} />
                    </td>
                );
            })}
        </tr>
    );
};

export const getTableSkeleton = (rows: number, columns: number) => {
    const rowArr = new Array(rows);
    const columnArr = new Array(columns);
    return (
        <table className='get-header-table-skeleton_table'>
            {getHeaderTableSkeleton(columnArr)}
            <tbody className='get-header-table-skeleton_tbody'>
                {[...rowArr].map((_, index) => {
                    return getRowSkeleton(columnArr, `row-${index}`);
                })}
            </tbody>
        </table>
    );
};
