import React, { useEffect, useMemo, useState } from 'react'
import { Select, Input } from '@sf/sibgu.package.ui'
import { IPayment } from 'shared/api/personalData/types'
import { IOptionItem } from '@sf/sibgu.package.ui/dist/types/types/select'
import { formatDate } from 'shared/utils/helpers'

interface IPaidInformationProps {
  payments: IPayment[]
  mainNumber: string
}

export const PaidInformation: React.FC<IPaidInformationProps> = (props) => {
  const { payments, mainNumber } = props

  const [selectedOption, setSelectedOption] = useState<IOptionItem>()
  const [selectedSumm, setSelectedSumm] = useState<IPayment>()

  const dateOptions = useMemo<IOptionItem[]>(
    () =>
      payments.map((payment) => ({
        id: payment.date,
        title: formatDate(payment.date, 'MM/YYYY'),
      })),
    [payments]
  )

  useEffect(() => {
    if (dateOptions.length) {
      setSelectedOption(dateOptions[0])
      setSelectedSumm(payments[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOptions])

  const changeOptionHandler = (_name: string, value: IOptionItem) => {
    const selectedPayment = payments.find((payment) => payment.date === value.id)
    if (selectedPayment) {
      setSelectedSumm(selectedPayment)
    }
  }

  return (
    <div>
      <div className='text subtitle contract__subtitle'>Информация об оплате</div>

      <div className='d-flex gap-24'>
        <Select
          dropdownPositionSelect='bottom'
          name={'numberOfSemester' + mainNumber}
          label='Семестр/год'
          optionList={dateOptions}
          chosenValue={selectedOption}
          setFieldValue={changeOptionHandler}
        />
        {/* <Input
                    name='semesterCost'
                    label='Стоимость периода'
                    disabled={true}
                /> */}
        <Input
          name={'paidForSemester' + mainNumber}
          label='Стоимость периода'
          disabled={true}
          value={selectedSumm?.summPlan || 0}
        />
        <Input
          name={'paidForSemester' + mainNumber}
          label='Оплачено за период'
          disabled={true}
          value={selectedSumm?.summFact || 0}
        />
      </div>
    </div>
  )
}
