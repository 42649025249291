import axios from 'axios'
import { API_URL, BASIC_TOKEN } from 'shared/consts/config'
import axiosInstance from './interceptors'

const headers = {
  Authorization: BASIC_TOKEN,
}

export const login = async (user: string, password: string) => {
  return axios.post(
    `${API_URL}/login`,
    {
      user,
      password,
    },
    {
      headers: headers,
    }
  )
}

export const changeIsFirstAuthorization = async () => {
  await axiosInstance.get('/changeIsFirstAuthorization')
}
