import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSemesterList } from 'shared/api/lists';


export const fetchSemesterList = createAsyncThunk(
    'semesterList/fetchAll',
    async (_, thunkAPI) => {
        try {
            const response = await getSemesterList();
            if (response.data?.status === 400) {
                return thunkAPI.rejectWithValue(response.data?.errorMessage);
            } else {
                return response.data;
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Не удалось получить список семестров.');
        }
    }
);