import dayjs from 'dayjs';

export const formatDate = (
    date: string | number | Date | dayjs.Dayjs | null | undefined,
    format = 'DD.MM.YYYY'
): string => {
    if (!date) {
        return '';
    }
    return dayjs(date).format(format);
};
