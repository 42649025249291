import { SortTableDataCallback } from '@sf/sibgu.package.ui/dist/types/components/Table/table'
import { ITableHeader } from '@sf/sibgu.package.ui/dist/types/components/Table/types'
import dayjs from 'dayjs'

type SortArrayType = (currentDataTable: any[], headerToSort: ITableHeader<keyof any>) => any[]

const sortDefaultAscending: SortArrayType = (currentDataTable, headerToSort) => {
  const fieldToSort = headerToSort.value
  if (fieldToSort === null) {
    return currentDataTable
  }
  if (fieldToSort === 'date') {
    return [...currentDataTable].sort((a, b) =>
      dayjs(a[fieldToSort], 'DD.MM.YYYY') > dayjs(b[fieldToSort], 'DD.MM.YYYY') ? 1 : -1
    )
  } else if (fieldToSort === 'number') {
    return [...currentDataTable].sort((a, b) => {
      const numA = a?.[fieldToSort] ? parseInt(a?.[fieldToSort]?.match(/\d+/)[0]) : 0
      const numB = b?.[fieldToSort] ? parseInt(b?.[fieldToSort]?.match(/\d+/)[0]) : 0

      // Если числа разные, сортируйте их по числовому значению
      if (numA < numB) return -1
      if (numA > numB) return 1

      // Если числа равны, сортируйте по исходным строкам
      return a?.[fieldToSort]?.localeCompare(b?.[fieldToSort])
    })
  }
  return [...currentDataTable].sort((a, b) => ((a[fieldToSort] ?? '') > (b[fieldToSort] ?? '') ? 1 : -1))
}

const sortDefaultDescending: SortArrayType = (currentDataTable, headerToSort) => {
  const fieldToSort = headerToSort.value
  if (fieldToSort === null) {
    return currentDataTable
  }
  if (fieldToSort === 'date') {
    return [...currentDataTable].sort((a, b) =>
      dayjs(a[fieldToSort], 'DD.MM.YYYY') < dayjs(b[fieldToSort], 'DD.MM.YYYY') ? 1 : -1
    )
  } else if (fieldToSort === 'number') {
    return [...currentDataTable].sort((a, b) => {
      const numA = a?.[fieldToSort] ? parseInt(a[fieldToSort].match(/\d+/)[0]) : 0
      const numB = b?.[fieldToSort] ? parseInt(b[fieldToSort].match(/\d+/)[0]) : 0
      // Если числа разные, сортируйте их по числовому значению
      if (numA > numB) return -1
      if (numA < numB) return 1
      // Если числа равны, сортируйте по исходным строкам
      return b?.[fieldToSort]?.localeCompare(a?.[fieldToSort])
    })
  }
  return [...currentDataTable].sort((a, b) => ((a[fieldToSort] ?? '') < (b[fieldToSort] ?? '') ? 1 : -1))
}

export const getSortedTableDate: SortTableDataCallback<any> = (currentDataTable, headerToSort, initialDataTable) => {
  if (headerToSort.sort === 'ascending') {
    return sortDefaultAscending(currentDataTable, headerToSort as any)
  } else if (headerToSort.sort === 'descending') {
    return sortDefaultDescending(currentDataTable, headerToSort as any)
  }
  return initialDataTable.slice()
}
