import { Table, Tabs } from '@sf/sibgu.package.ui'
import { SEMESTER_TITLE_LIST } from 'shared/consts/lists'
import { getSortedTableDate } from 'shared/lib/utils/sorting'
import { getTableSkeleton } from 'shared/lib/utils/getTableSkeleton'
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux'
import { useEffect, useMemo, useState } from 'react'
import { fetchCurrentAttestation } from 'entities/currentAttestation/modal/currentAttestation'
import { ITab } from '@sf/sibgu.package.ui/dist/types/components/Tabs/tabs'
import {
  COLUMN_WIDTH_WITH_INTERIM_ATTESTATION,
  COLUMN_WIDTH_WITH_RATING_SCORE,
  COLUMN_WIDTH_WITH_RATING_SCORE_AND_INTERIM_ATTESTATION,
  NUMBER_ALL_HEADER,
  NUMBER_HEADERS_INTERIM_ATTESTATION,
  NUMBER_HEADERS_WITH_RATING_SCORE,
  NUMBER_HEADERS_WITHOUT_FILTERS,
} from 'entities/currentAttestation/consts/currentAttestationLists'
import clsx from 'clsx'
import useStrangeTableHeight from 'shared/hooks/useStrangeTableHeight'

export const CurrentAttestation = () => {
  const { loading, data, headers } = useAppSelector((state) => state.currentAttestationReducer)
  const { currentSpecialty } = useAppSelector((state) => state.choiceSpecialtyReducer)
  const { creditBookList: semesterList } = useAppSelector((state) => state.semesterListReducer)
  const dispatch = useAppDispatch()

  const tableHeight = useStrangeTableHeight()

  const [selectedTab, setSelectedTab] = useState<number>()

  const getColumnWidthTable = useMemo(() => {
    switch (headers.length) {
      case NUMBER_ALL_HEADER:
        return COLUMN_WIDTH_WITH_RATING_SCORE_AND_INTERIM_ATTESTATION
      case NUMBER_HEADERS_WITH_RATING_SCORE:
        return COLUMN_WIDTH_WITH_RATING_SCORE
      default:
        return COLUMN_WIDTH_WITH_INTERIM_ATTESTATION
    }
  }, [headers])

  const getCurrentAttestationItem = (index: number) => {
    localStorage.setItem('сurrentAttestationTab', index + '')
    if (currentSpecialty && !data[index] && tabsLists[index]) {
      dispatch(
        fetchCurrentAttestation({
          specialityId: currentSpecialty.id,
          semesterId: tabsLists[index].key,
          creditBookId: currentSpecialty.creditbook,
          index: index,
        })
      )
    }
  }

  const semesters = useMemo(() => {
    if (!currentSpecialty || semesterList?.length <= 0) {
      return []
    }
    const semestersOfCurrentSpecialty = semesterList.find(
      (semesterListItem) => semesterListItem.creditbook === currentSpecialty?.creditbook
    )

    return semestersOfCurrentSpecialty ? semestersOfCurrentSpecialty?.semesterList : []
  }, [currentSpecialty, semesterList])

  const tabsLists = useMemo(() => {
    return semesters?.map(
      (item, index): ITab => ({
        key: item.id,
        title: SEMESTER_TITLE_LIST[item.label],
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [semesters, data, loading, selectedTab])

  useEffect(() => {
    if (selectedTab == null) {
      const savedTab = localStorage.getItem('сurrentAttestationTab')
      if (savedTab) {
        savedTab && setSelectedTab(+savedTab)
      } else if (tabsLists.length) {
        setSelectedTab(tabsLists.length - 1)
      }
    }
  }, [tabsLists, selectedTab])

  useEffect(() => {
    selectedTab != null && getCurrentAttestationItem(selectedTab)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, currentSpecialty])

  const childrenTabs = useMemo(() => {
    if (!data) {
      return
    }
    let children = []
    for (let i = 0; i < tabsLists?.length; i++) {
      if (loading && !data[i]) {
        children.push(getTableSkeleton(3, 6))
        continue
      }
      if (!data[i]) {
        children.push(<></>)
        continue
      }
      children.push(
        <Table
          bodyHeight={`${tableHeight}px`}
          dataTable={data[i]}
          headers={headers}
          columnWidth={getColumnWidthTable}
          sortTableData={getSortedTableDate}
          className={clsx(
            (data[i].length === 0 ||
              headers.length === NUMBER_HEADERS_WITHOUT_FILTERS ||
              headers.length === NUMBER_HEADERS_WITH_RATING_SCORE ||
              headers.length === NUMBER_HEADERS_INTERIM_ATTESTATION) &&
              'current-attestation--table-base',
            headers.length === NUMBER_HEADERS_WITHOUT_FILTERS && 'current-attestation--table-base-without-filters',
            'current-attestation__body-table'
          )}
        />
      )
    }
    return children
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, currentSpecialty, semesterList, loading, headers, tableHeight])

  return (
    <Tabs
      className='current-attestation__body-tabs'
      selected={selectedTab}
      items={tabsLists}
      children={childrenTabs}
      onSelect={setSelectedTab}
    />
  )
}
