import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const AuthenticationGuard: FC = () => {
    const isAuthenticated = localStorage.token && localStorage.token !== '';

    if (!isAuthenticated) {
        return <Navigate to='/authorization' />;
    }
    return <Outlet />;
};
