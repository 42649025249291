import React from 'react';
import { Input, ButtonIcon } from '@sf/sibgu.package.ui';
import { formatDate } from 'shared/utils/helpers';
import { IAdditionalAgreements } from 'shared/api/personalData/types';

interface IExtraContractProps {
    additionalAgreements: IAdditionalAgreements[];
    mainNumber: string;
}

export const ExtraContract: React.FC<IExtraContractProps> = (props) => {
    const { mainNumber, additionalAgreements } = props;
    return (
        <div>
            <div className='text subtitle contract__subtitle'>
                Дополнительные соглашения
            </div>

            {additionalAgreements.map((item, index) => (
                <React.Fragment key={item.number}>
                    <div className='d-flex gap-24'>
                        <Input
                            name={
                                'numberOfAdditionalAgreement' +
                                mainNumber +
                                item.number
                            }
                            label='Номер соглашения'
                            disabled={true}
                            value={item.number}
                        />
                        <Input
                            name={
                                `dateOfAdditionalAgreement` +
                                mainNumber +
                                item.number
                            }
                            label='Дата соглашения'
                            disabled={true}
                            value={formatDate(item.date)}
                        />
                        <ButtonIcon icon='download' tooltip='Загрузить' />
                    </div>
                    {index !== additionalAgreements.length - 1 && (
                        <div className='spacer-1' />
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};
