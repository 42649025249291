import { useEffect, useMemo } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import { Button, Input, InputWithSelects } from '@sf/sibgu.package.ui'
import { TOption } from '@sf/sibgu.package.ui/dist/types/components/InputWithSelects/inputWithSelects'
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux'
import { formValidationContactDetails } from 'widgets/contentModalUserInfo/contactDetails/lib/formValidationContactDetails'
import {
  AddressFieldsEnum,
  changeAddressField,
  changeFormField,
  IPersonalDataAddress,
  IPersonalDataForm,
  setList,
} from 'entities/personalData/model/userPersonalDataSlice'
import { fetchAddress, fetchUpdateUserContactInfo, fetchUserContactInfo } from 'entities/personalData/model/thunk'
import { IUserInfo, ResponseGetAddress } from 'shared/api/personalData/types'
import { getNextAddressLevel, RUSSIAN_GUID, getAddressListToFetch } from '../lib/const'
import { initialAddressValue } from 'entities/personalData/lib/helpers'
import { RussianAddress } from './RussianAddress'
import { EAUEAdress } from './EAUEAdress'
import { AnotherCountryAddress } from './AnotherCountryAddress'
import './contactDetails.scss'

interface ContactDetailsProps {
  onCancel?(): void
}

export const ContactDetails: React.FC<ContactDetailsProps> = (props) => {
  const { onCancel } = props

  // const [showFormButtons, setShowFormButtons] = useState(false)
  const { fullName } = useAppSelector((state) => state.userReducer)
  const { form, lists } = useAppSelector((state) => state.userPersonalDataReducer)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchUserContactInfo()).then((res) => {
      const data = res.payload as IUserInfo
      if (data.Address.Country.Guid === RUSSIAN_GUID) {
        const listToFetch = getAddressListToFetch(data.Address)
        listToFetch.forEach((item) =>
          dispatch(fetchAddress({ ...item })).then((res) => {
            dispatch(
              setList({
                field: item.field,
                value: res.payload as ResponseGetAddress[],
              })
            )
          })
        )
      }
    })

    dispatch(fetchAddress({ Level: 0 })).then((res) => {
      dispatch(
        setList({
          field: 'Country',
          value: res.payload as ResponseGetAddress[],
        })
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isRussianSelected = useMemo<boolean>(() => {
    return form.Address.Country.Guid === RUSSIAN_GUID
  }, [form.Address.Country])

  const isEAUESelected = useMemo<boolean>(() => {
    return form.Address.Country.EAEUMember || false
  }, [form.Address.Country])

  const onReset = () => {
    resetForm()
    onCancel?.()
    // setShowFormButtons(false)
  }

  const sendContactDetails = () => {
    dispatch(
      fetchUpdateUserContactInfo({
        ...form,
        Address: {
          Index: form.Address.Index,
          Appartament: form.Address.Appartament,
          Country: form.Address.Country.Guid || form.Address.Country.Name,
          Region: form.Address.Region.Guid || form.Address.Region.Name,
          District: form.Address.District.Guid || form.Address.District.Name,
          City: form.Address.City.Guid || form.Address.City.Name,
          Locality: form.Address.Locality.Guid || form.Address.Locality.Name,
          Territory: form.Address.Territory.Guid || form.Address.Territory.Name,
          Street: form.Address.Street.Guid || form.Address.Street.Name,
          House: form.Address.House.Guid || form.Address.House.Name,
        },
      })
    ).then(onReset)
  }

  const formik = useFormik({
    initialValues: {
      fullName: fullName,
      ...form,
    },
    validationSchema: formValidationContactDetails,
    enableReinitialize: true,
    onSubmit: () => sendContactDetails(),
  })
  const { values, resetForm } = formik

  useEffect(() => {
    if (values.Email || values.Phone || values.Address) {
      // setShowFormButtons(true)
    }
  }, [values])

  const onSelectAddressHandler = (field: keyof IPersonalDataAddress) => (option: TOption<ResponseGetAddress>) => {
    dispatch(
      changeAddressField({
        field: field,
        value: option.meta !== null ? (option.meta as ResponseGetAddress) : initialAddressValue,
      })
    )
    //! если выбрана страна Россия
    if ((field === 'Country' && option.value === RUSSIAN_GUID) || isRussianSelected) {
      const nextLevel = getNextAddressLevel(field as keyof typeof AddressFieldsEnum)

      // если регион\район\город\нас.пункт\территория получаем список улиц
      if (
        field === 'Region' ||
        field === 'District' ||
        field === 'City' ||
        field === 'Locality' ||
        field === 'Territory'
      ) {
        dispatch(fetchAddress({ Level: AddressFieldsEnum.Street, ParentGuid: option.value })).then((res) => {
          dispatch(
            setList({
              field: 'Street',
              value: res.payload as ResponseGetAddress[],
            })
          )
        })
      }

      // если район получаем список нас.пунктов
      if (field === 'District') {
        dispatch(fetchAddress({ Level: AddressFieldsEnum.Locality, ParentGuid: option.value })).then((res) => {
          dispatch(
            setList({
              field: 'Locality',
              value: res.payload as ResponseGetAddress[],
            })
          )
        })
      }
      // если территория получаем список домов
      if (field === 'Territory') {
        dispatch(fetchAddress({ Level: AddressFieldsEnum.House, ParentGuid: option.value })).then((res) => {
          dispatch(
            setList({
              field: 'House',
              value: res.payload as ResponseGetAddress[],
            })
          )
        })
      }
      // если регион получаем список городов
      if (field === 'Region') {
        dispatch(fetchAddress({ Level: AddressFieldsEnum.City, ParentGuid: option.value })).then((res) => {
          dispatch(
            setList({
              field: 'City',
              value: res.payload as ResponseGetAddress[],
            })
          )
        })
      }
      // по порядку получаем следующий список, выбрали страну -> получили список регионов
      // и т.д. по форме для страны "Россия"
      if (nextLevel) {
        dispatch(fetchAddress({ Level: nextLevel.level, ParentGuid: option.value })).then((res) => {
          dispatch(
            setList({
              field: nextLevel.field,
              value: res.payload as ResponseGetAddress[],
            })
          )
        })
      }
    }
  }

  const onInputChangeHandler = (field: keyof IPersonalDataAddress) => (ev: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeAddressField({
        field: field,
        value: { Name: ev.target.value, Guid: '' },
      })
    )
  }

  const onChangeFormValues =
    (field: keyof Omit<IPersonalDataForm, 'Address'>) => (ev: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(changeFormField({ field, value: ev.target.value }))
    }

  return (
    <FormikProvider value={formik}>
      <Form className='contact-details'>
        <div className='contact-details__title text bold fz-24'>Контактные данные</div>
        <div className='contact-details__input-container'>
          <Input name='fullName' label='ФИО' className='contact-details__input' disabled />
          <Input
            name='Email'
            value={form.Email}
            onChange={onChangeFormValues('Email')}
            label='E-mail'
            className='contact-details__input'
          />
          <Input
            name='Phone'
            value={form.Phone}
            onChange={onChangeFormValues('Phone')}
            label='Телефон'
            className='contact-details__input'
          />

          <InputWithSelects
            name='country'
            label='Страна'
            required
            content={lists?.Country || []}
            value={values.Address.Country.Name}
            onSelect={onSelectAddressHandler('Country')}
            className='contact-details__input'
          />

          {isRussianSelected && (
            <RussianAddress lists={lists} form={form} onSelectAddressHandler={onSelectAddressHandler} values={values} />
          )}

          {form.Address.Country.Guid && isEAUESelected && !isRussianSelected && (
            <EAUEAdress form={form} onInputChangeHandler={onInputChangeHandler} />
          )}
          {form.Address.Country.Guid && !isEAUESelected && !isRussianSelected && (
            <AnotherCountryAddress form={form} onInputChangeHandler={onInputChangeHandler} />
          )}

          {/* {showFormButtons && ( */}
          <div className='contact-details__group-button d-flex jst-btw'>
            <Button
              type='reset'
              text='Отмена'
              theme='text'
              onClick={onReset}
              className='contact-details__group-button-item'
            />
            <Button type='submit' text='Сохранить' className='contact-details__group-button-item' />
          </div>
          {/* )} */}
        </div>
      </Form>
    </FormikProvider>
  )
}
