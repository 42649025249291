import { createAsyncThunk } from '@reduxjs/toolkit';
import { login } from 'shared/api/login';

interface ILoginData {
    user: string;
    password: string;
}

export const fetchLogin = createAsyncThunk(
    'login/fetchAll',
    async (data: ILoginData, thunkAPI) => {
        const { user, password } = data;
        try {
            const response = await login(user, password);
            if (response.data?.status === 400) {
                return thunkAPI.rejectWithValue(response.data?.errorMessage);
            } else {
                localStorage.setItem('token', response.data.token);
                return response.data;
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Не удалось выполнить вход.');
        }
    }
);
