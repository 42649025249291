import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchChangePassword } from './changePassword';


interface IChangePasswordState {
    loading: boolean;
    error: null | string;
    response: string;
}

const initialState: IChangePasswordState = {
    loading: false,
    error: null,
    response: '',
};

export const changePasswordSlice = createSlice({
    name: 'changePasswordSlice',
    initialState,
    reducers: {
        clearChangePasswordData: (state) => {
            state.loading = false;
            state.error = '';
            state.response = '';
        },
    },
    extraReducers: {
        [fetchChangePassword.pending.type]: (state) => {
            state.loading = true;
        },
        [fetchChangePassword.fulfilled.type]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.loading = false;
            state.error = '';
            state.response = action.payload;
        },
        [fetchChangePassword.rejected.type]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { clearChangePasswordData } = changePasswordSlice.actions;
export default changePasswordSlice.reducer;
