import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useMemo } from 'react'
import { TargetContract } from '../components/TargetContract'
import { PaidContract } from '../components/PaidContract'
import { ExtraContract } from '../components/ExtraContract'
import { PaidInformation } from '../components/PaidInformation'
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux'
import { fetchUserContracts } from 'entities/personalData/model/thunk'
import { SkeletonContracts } from '../components/SkeletonContracts'
import './contract.scss'

export const Contract: React.FC = () => {
  const { loading, data } = useAppSelector((state) => state.userContractsSliceReducer)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchUserContracts())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit: () => {},
  })

  const targetContract = useMemo(() => data.filter((item) => item.targetOrganization)[0], [data])

  const anotherContract = useMemo(() => data.filter((item) => !item.targetOrganization), [data])

  if (loading) {
    return <SkeletonContracts />
  }

  return (
    <div className='main-div contract'>
      <div className='text title'>Договор</div>

      <FormikProvider value={formik}>
        <div className='d-flex column'>
          {!!targetContract && (
            <React.Fragment>
              <TargetContract
                targetOrganization={targetContract.targetOrganization}
                date={targetContract.date}
                number={targetContract.number}
              />

              {!!targetContract.additionalAgreements.length && (
                <ExtraContract
                  additionalAgreements={targetContract.additionalAgreements}
                  mainNumber={targetContract.number}
                />
              )}

              {!!targetContract.payments.length && (
                <PaidInformation payments={targetContract.payments} mainNumber={targetContract.number} />
              )}
            </React.Fragment>
          )}
          {!!anotherContract.length &&
            anotherContract.map((item) => (
              <React.Fragment key={item.number}>
                <PaidContract date={item.date} number={item.number} />
                {!!item.additionalAgreements.length && (
                  <ExtraContract additionalAgreements={item.additionalAgreements} mainNumber={item.number} />
                )}

                {!!item.payments.length && <PaidInformation payments={item.payments} mainNumber={item.number} />}
              </React.Fragment>
            ))}
        </div>
      </FormikProvider>
    </div>
  )
}
