import React from 'react';
import { Input, ButtonIcon } from '@sf/sibgu.package.ui';
import { formatDate } from 'shared/utils/helpers';

interface ITargetContractProps {
    targetOrganization: string;
    number: string;
    date: string;
}

export const TargetContract: React.FC<ITargetContractProps> = (props) => {
    const { targetOrganization, number, date } = props;
    return (
        <div>
            <div className='text subtitle contract__subtitle'>
                Договор о целевом обучении
            </div>

            <div className='d-flex gap-24'>
                <Input
                    name='targetCntractNumber'
                    label='Номер договора'
                    disabled={true}
                    value={number}
                />
                <Input
                    name='targetCntractDate'
                    label='Дата договора'
                    disabled={true}
                    value={formatDate(date)}
                />
                <Input
                    name='company'
                    label='Предприятие'
                    disabled={true}
                    value={targetOrganization}
                />
                <ButtonIcon icon='download' tooltip='Загрузить' />
            </div>
        </div>
    );
};
