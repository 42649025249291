import React from 'react';
// import axios from 'axios';
// import cheerio from 'cheerio';
import { AuthLoginForm } from 'shared/ui/AuthLoginForm';
import './../../electronicEducational.scss';

export const ElectronicDistEducation: React.FC = () => {
    let formRef = React.useRef<HTMLFormElement>(null);

    // const fetchFormData = async (): Promise<void> => {
    //     // const data = await axios
    //     //     .get('https://dl.sibsau.ru/login/index.php')
    //     //     .then((data: any) => data.data);
    //     // console.log('data', data);
    //     // const data = `<form class="mt-3" action="https://dl.sibsau.ru/login/index.php" method="post" id="login">
    //     //     <input id="anchor" type="hidden" name="anchor" value="">
    //     //     <script>document.getElementById('anchor').value = location.hash;</script>
    //     //     <input type="hidden" name="logintoken" value="ZWDHWLKBImJjSDnZNDy3M5VIA7gGsDDK">
    //     //     <div class="form-group">
    //     //         <label for="username" class="sr-only">
    //     //                 Логин
    //     //         </label>
    //     //         <input type="text" name="username" id="username" class="form-control" value="" placeholder="Логин" autocomplete="username">
    //     //     </div>
    //     //     <div class="form-group">
    //     //         <label for="password" class="sr-only">Пароль</label>
    //     //         <input type="password" name="password" id="password" value="" class="form-control" placeholder="Пароль" autocomplete="current-password">
    //     //     </div>
    //     //     <button type="submit" class="loginbtn btn btn-primary btn-block mt-3" id="loginbtn">Вход</button>
    //     // </form>`;
    //     // console.log(data);
    //     // const $ = cheerio.load(data);
    //     // const loginForm = $('#login').parent();
    //     // console.log('loginForm', loginForm.html());
    //     // loginForm
    //     //     .find('form')
    //     //     .attr('action', 'https://dl.sibsau.ru/login/index.php');
    //     // const usernameInput = loginForm.find('input[name="username"]');
    //     // const passwordInput = loginForm.find('input[name="password"]');
    //     // usernameInput.val('дербень александр михайлович');
    //     // passwordInput.val('Zxcvbn_1');
    //     // Получение HTML-кода формы
    //     // const formHTML = loginForm.html();

    //     // console.log(formHTML);

    //     // @ts-ignore
    //     // formRef.current.innerHTML = formHTML;
    //     // @ts-ignore
    //     // formRef.current.action = 'https://dl.sibsau.ru/login/index.php';
    //     // @ts-ignore
    //     // formRef.current.method = 'post';
    //     // Обновление состояния и сохранение ссылки на форму
    //     // setFormRef(loginForm);
    // };
    const subSections = [
        {
            title: 'Портал электронно-дистанционного обучения СибГУ им. М.Ф. Решетнева',
            subtitle:
                'Сервера электронно-дистанционного обучения вуза, помощь по работе для преподавателей и студентов, нормативная документация, образовательные ресурсы, образцы документов и многое другое.',
            link: 'https://el.sibsau.ru',
        },
        {
            title: 'Электронные онлайн-курсы вуза на внешних образовательных платформах',
            link: ' https://el.sibsau.ru/page/lms',
        },
    ];
    const renderSectionList = () =>
        subSections.map((section, index) => (
            <a
                key={index}
                rel='noreferrer'
                href={section.link}
                target='_blank'
                className='science-library__item'>
                {section.title}
                <div className='science-library__item-subtitle'>
                    {section?.subtitle}
                </div>
            </a>
        ));

    // const buttonClick = async () => {
    //     //@ts-ignore
    //     await fetchFormData();
    //     const form = formRef?.current;
    //     if (form) {
    //         form.target = '_blank'; // Отправка формы на новой вкладке
    //         form.submit();
    //     }
    // };

    return (
        <div className='main-div'>
            <div className='d-flex jst-btw al-cent'>
                <div className='text title title-pos'>
                    Электронно-дистанционное обучение
                </div>
            </div>

            <AuthLoginForm ref={formRef} />

            <div className='spacer-3' />
            <div className='science-library__list'>{renderSectionList()}</div>
        </div>
    );
};
